import React, { createContext, FC, useContext } from "react";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

export enum LanguageType {
  ENGLISH = "en",
  FRENCH = "fr",
  GERMAN = "de",
  LUXEMBOURGISH = "lu",
}

export interface ContextProps {
  selectedLang: LanguageType;
}
const initialState: ContextProps = {
  selectedLang: LanguageType.FRENCH,
};

function getConfig(): ContextProps {
  // default case: load language from local storage -> top priority since user already customised language)
  const ls = localStorage.getItem(I18N_CONFIG_KEY);

  let localAppStorageLang;
  if (ls) {
    try {
      const appStorage = JSON.parse(ls) as ContextProps;
      localAppStorageLang = appStorage?.selectedLang?.substring(0, 2);
      if (localAppStorageLang) {
        return appStorage;
      }
    } catch (er) {
      console.error(er);
    }
  }

  // case 2: no language defined in local storage: This can either be the case because user
  // is not logged in and/or never visited page before (browser cache for immosign is empty)
  const browserLanguage = navigator.language;

  if (browserLanguage) {
    const langShort: any = browserLanguage.substring(0, 2);
    let enumKey: any = Object.keys(LanguageType)[Object.values(LanguageType).indexOf(langShort)];
    if (enumKey) {
      return {
        selectedLang: langShort,
      };
    }
  }

  //fallback to french, if for whatever reason, non of the above worked
  return initialState;
}

export function setLanguage(lang: string = LanguageType.ENGLISH) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

const I18nContext = createContext<ContextProps>(initialState);

const useLang = () => {
  return useContext(I18nContext).selectedLang;
};

const MetronicI18nProvider: FC = ({ children }) => {
  const lang = getConfig();
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};

export { MetronicI18nProvider, useLang };
