import { useUsers } from "../../../../../hooks/use-users";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import React from "react";
import { UserLoading } from "../../../../../contexts/users-context";
import { useLoadings } from "../../../../../hooks/use-loadings";
import { KTSVG } from "../../../../../../_metronic/helpers";

type params = {
  openDialog: boolean;
  setOpenDialog: Function;
  type: string;
  action: Function;
};
export const UserActionDialog: React.FC<params> = ({ openDialog, setOpenDialog, action, type }) => {
  const { selectedUser } = useUsers();
  const { loadings } = useLoadings();
  return (
    <Modal show={openDialog} centered backdrop='static'>
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg'>
          <FormattedMessage id={`USERS.${type}.TITLE`} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          <FormattedMessage
            id={
              !loadings.includes(UserLoading[action.name as keyof typeof UserLoading])
                ? `USERS.${type}.CONFIRM`
                : "GLOBAL.LOADING"
            }
          />
        </span>
        {!loadings.includes(UserLoading[action.name as keyof typeof UserLoading]) &&
          type === "RESET_SIGNATORIES_SIGNATURE" && (
            <div className='d-flex mt-5 '>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-info'
              />
              <div style={{ marginLeft: 8 }} className='small'>
                <FormattedMessage id={`USERS.${type}.DESCRIPTION`} />
              </div>
            </div>
          )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type='button'
            onClick={() => setOpenDialog(false)}
            className='btn btn-light btn-elevate'
          >
            <FormattedMessage id='GLOBAL.CANCEL' />
          </button>
          <button
            type='button'
            onClick={() => action(selectedUser.id)}
            className='ms-2 btn btn-delete btn-danger btn-elevate'
            disabled={loadings.includes(UserLoading[action.name as keyof typeof UserLoading])}
          >
            <FormattedMessage id={"GLOBAL.CONFIRM"} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
