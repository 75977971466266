import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { ForgotPassword } from "./components/ForgotPassword";
import { Footer } from "src/_metronic/layout/components/Footer";
import { LanguageSelectorDropdown } from "../../../_metronic/partials/layout/header-menus/LanguageSelectorDropdown";

// noinspection DuplicatedCode
const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex justify-content-end'>
        <div style={{ marginRight: 24, marginTop: 12 }}>
          <LanguageSelectorDropdown />
        </div>
      </div>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='/' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl("/media/logos/logo.png")} className='h-100px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-sm-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <Footer mode='login' />
      {/* end::Footer */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot_password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
