import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { AuthPage, Logout } from "../modules/auth";
import App from '../App';
import { useAuth } from "../hooks/use-auth";
import { AuthChallengesPage } from "../modules/auth-challenges";
import { SignPage } from "../pages/sign/SignPage";
import { SignProvider } from "../contexts/sign-context";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { isAuthenticated, challengeName } = useAuth();
  const auth = useAuth();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route
            path='sign/:signatureId'
            element={
              <SignProvider>
                <SignPage />
              </SignProvider>
            }
          />
          {isAuthenticated ? (
            <>
              {challengeName ? (
                <>
                  <Route path='challenges/*' element={<AuthChallengesPage />} />
                  <Route
                    path='*'
                    element={<Navigate to={`/challenges/${challengeName.toLowerCase()}`} />}
                  />
                </>
              ) : auth?.user?.gdprApprovedAt || auth?.groups?.includes("Admin") ? (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              ) : (
                <>
                  <Route path='/init/*' element={<AuthChallengesPage />} />
                  <Route index element={<Navigate to='/init/gdpr' />} />
                </>
              )}
            </>
          ) : (
            // TODO: handle public signature URL
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
