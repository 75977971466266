/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import cn from "clsx";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { AVAILABLE_LANGUAGES } from "../../../i18n/languages";
import { DropdownTopbarItemToggler } from "./DropdownTopbarItemToggler";
import { LanguageType, setLanguage, useLang } from "../../../i18n/Metronici18n";
import "./LanguageSelectorDropdown.scss";
import { KTSVG } from "../../../helpers";

const I18N_CONFIG_KEY = "i18nConfig";

export const setI18Language = (lang: LanguageType) => {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
};
export const LanguageSelectorDropdown = () => {
  const lang: any = useLang();

  useEffect(() => {
    const langShort = lang.split("-")[0];
    setI18Language(
      Object.values(LanguageType).includes(langShort) ? langShort : LanguageType.FRENCH
    );
  }, [lang]);

  const currentLanguage = AVAILABLE_LANGUAGES.find((x) => x.lang === lang);
  return (
    // @ts-ignore
    <Dropdown drop='down'>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id='dropdown-toggle-my-cart'>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id='language-panel-tooltip'>
              <FormattedMessage id='GLOBAL.SELECT_LANGUAGE' />
            </Tooltip>
          }
        >
          <div className='btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1'>
            <img
              className='h-25px w-25px rounded-1'
              src={currentLanguage?.flag}
              alt={currentLanguage?.name}
            />
            <div>
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-1' />
            </div>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className='dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right'
        style={{ width: "max-content" }}
      >
        <ul className='navi navi-hover py-4'>
          {AVAILABLE_LANGUAGES.map((language) => (
            <li
              key={language.lang}
              className={cn("navi-item", {
                active: language.lang === currentLanguage?.lang,
              })}
            >
              <a href='#' onClick={() => setLanguage(language.lang)} className='navi-link'>
                <span className='symbol symbol-20 rounded-1'>
                  <img src={language.flag} alt={language.name} />
                </span>
                <span className='navi-text'>{language.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};
