import { User } from "../../types/user";

const ADMIN: string = "Admin";
const MASTER_USER_EMAIL: string = "master@kodehyve.com";

export const isMasterUser = (user: User) => {
  if (!user) {
    return true;
  }
  return user?.email?.toLowerCase() === MASTER_USER_EMAIL;
};

export const isAdmin = (user: any) => {
  return (
    user?.groups?.includes(ADMIN) ||
    user?.cognitoUser?.signInUserSession?.accessToken?.payload?.["cognito:groups"]?.includes(ADMIN)
  );
};
