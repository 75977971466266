import { getDocument, PDFDocumentLoadingTask } from "pdfjs-dist";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";

interface IPageRendered {
  image: HTMLImageElement;
  id: string;
  x: number;
  y: number;
  draggable: boolean;
  page: number;
}

export const loadPdf = async (source: any): Promise<PDFDocumentProxy> => {
  let pdfFile: PDFDocumentProxy;
  const pdfLoadingTask: PDFDocumentLoadingTask = getDocument(source);
  pdfFile = await pdfLoadingTask.promise;

  return pdfFile;
};

export const getPdfPageCount = async (source: any): Promise<number> => {
  const pdf = await loadPdf(source);
  return pdf?._pdfInfo?.numPages || 0;
};

export const loadPageRendered = async (pdf: PDFDocumentProxy, pageIndex: number) => {
  let indexPage = pageIndex;
  if (!pdf) {
    console.warn("no pdf source set");
  } else if (pdf && pdf?._pdfInfo?.numPages === 0) {
    console.warn("pdf is empty");
  } else if (pageIndex > pdf?._pdfInfo?.numPages) {
    indexPage = 0;
  }

  try {
    const page = await pdf?.getPage(indexPage + 1);

    if (page) {
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const renderContext: any = { canvasContext: ctx, viewport: viewport };

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render(renderContext).promise;

      const image = new window.Image();

      image.src = canvas.toDataURL();

      const pageRendered: IPageRendered = {
        image: image,
        id: "pdf-page-image-" + indexPage,
        x: 0,
        y: 0,
        draggable: false,
        page: indexPage,
      };

      return { pageRendered, totalPages: pdf?._pdfInfo?.numPages };
    }
  } catch (e) {
    console.error("failed to load page", e);
    return null;
  }
};
