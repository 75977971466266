import clsx from "clsx";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useLayout, usePageData } from "../../../core";

const DefaultTitle: FC = () => {
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData();
  const { config, classes } = useLayout();
  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx("page-title d-flex", classes.pageTitle.join(" "))}
    >
      {/* begin::Title */}
      {pageTitle && (
        <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2' />
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
        </h1>
      )}
      {/* end::Title */}
      <>
        <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
          <li className='breadcrumb-item text-dark'>{pageTitle}</li>
          {(pageBreadcrumbs ?? []).map((item, index) => (
            <li className={"breadcrumb-item"} key={`${item.path}${index}`}>
              <span className='svg-icon svg-icon-white svg-icon-md opacity-75 mx-3'>{">"}</span>
              {!item.isActive ? (
                <Link className='text-muted text-hover-primary' to={item.path}>
                  {item.title}
                </Link>
              ) : (
                <span className='text-dark'>{item.title}</span>
              )}
            </li>
          ))}
        </ul>
      </>
    </div>
  );
};

export { DefaultTitle };
