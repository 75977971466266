import type { FC, ReactNode } from "react";
import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { API, Auth } from "aws-amplify";
import type { User } from "../../types/user";
import { LayoutSplashScreen } from "../../_metronic/layout/core";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useLoadings } from "../hooks/use-loadings";
import { UserLoading } from "./users-context";
import { Intercom } from "../utils/intercomUtils";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  challengeName?: string;
  user: User | null;
  groups: string[];
}

interface AuthContextValue extends State {
  login: (username: string, password: string) => Promise<any>;
  logout: () => Promise<void>;
  register: (email: string, password: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (username: string, code: string, newPassword: string) => Promise<void>;
  completeNewPassword: (user: CognitoUser | any, newPassword: string) => Promise<void>;
  changePassword: (user: CognitoUser | any, passwords: any) => Promise<any>;
  forgotPassword: (email: string) => Promise<void>;
  forgotPasswordSubmit: (email: string, code: string, newPassword: string) => Promise<void>;
  setUserGdpr: (userId: string, gdprApprovedAt: Date) => Promise<void>;
  refreshUserMe: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  groups: [],
};

export enum AuthLoading {
  changePassword = "changePassword",
}

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  completeNewPassword: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  forgotPasswordSubmit: () => Promise.resolve(),
  setUserGdpr: (userId: string, gdprApprovedAt: Date) => Promise.resolve(),
  refreshUserMe: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const { loading, loadingFinished } = useLoadings();
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    const initialise = async (): Promise<void> => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        let user: any = {};
        try {
          user = await getUserInformation();
          if (user) {
            const url =
              user.logoUrl.replace("avatar.png", "thumbnail.png") + "?" + new Date().getTime();
            user.thumbnailValid = await fetch(url).then((res) => {
              return res.status === 200;
            });
          }
        } catch (e) {
          // DO NOTHING
        }
        // console.log("user:", user);
        setState({
          isInitialized: true,
          isAuthenticated: true,
          user: {
            ...user,
            cognitoUser,
          },
          challengeName: cognitoUser.challengeName,
          groups: cognitoUser.signInUserSession?.accessToken?.payload?.["cognito:groups"] || [],
        });
      } catch (error) {
        console.log("user error:", error);
        setState({
          isInitialized: true,
          isAuthenticated: false,
          user: null,
          groups: [],
        });
      }
    };

    initialise().then();
  }, []);

  const refreshUserMe = async (): Promise<void> => {
    let user: any = {};
    try {
      user = await getUserInformation();
      if (user) {
        const url =
          user.logoUrl.replace("avatar.png", "thumbnail.png") + "?" + new Date().getTime();
        user.thumbnailValid = await fetch(url).then((res) => {
          return res.status === 200;
        });
      }
    } catch (e) {
      // DO NOTHING
    }
    setState({
      ...state,
      user: {
        ...state?.user,
        ...user,
      },
    });
  };

  const login = async (username: string, password: string): Promise<any> => {
    const cognitoUser = await Auth.signIn(username, password);
    if (
      cognitoUser.challengeName &&
      !["NEW_PASSWORD_REQUIRED"].includes(cognitoUser.challengeName)
    ) {
      console.error(
        `Unable to login, because challenge "${cognitoUser.challengeName}" is mandated and we did not handle this case.`
      );
      return;
    }
    let user: any = {};
    try {
      const userId = cognitoUser.username;

      if (!cognitoUser.challengeName) {
        if (userId) {
          loading(UserLoading.fetchUserById);
          user = await getUserInformation();
        }
      }
      const groups =
        user?.cognitoUser?.signInUserSession?.accessToken?.payload?.["cognito:groups"] || [];
      user = { ...user, cognitoUser, groups: groups };
      setState({
        ...state,
        isInitialized: true,
        isAuthenticated: true,
        user: user,
        challengeName: cognitoUser.challengeName,
        groups: cognitoUser.signInUserSession?.accessToken?.payload?.["cognito:groups"] || [],
      });
      return user;
    } catch (e) {
      console.error("error", e);
      // DO NOTHING
    }
  };

  const getUserInformation = async (): Promise<any> => {
    const user = await API.get("API", `/users/me`, {});
    const hostname = window?.location?.hostname || "";
    if (
      user.intercom &&
      !hostname.includes("testpr") &&
      !hostname.includes(".d.kodehyve.com")
      // !hostname.includes("localhost")
    ) {
      Intercom.boot({
        user_id: user.id,
        user_hash: user.intercom.hash,
        name: user.name,
        email: user.email,
        company: {
          id: user.intercom.companyId,
          name: user.intercom.companyName,
          website: user.intercom.companyWebsite,
        },
      });
    }
    return user;
  };

  const logout = async (): Promise<void> => {
    Intercom.shutdown();
    await Auth.signOut();
    setState({
      isInitialized: true,
      isAuthenticated: false,
      user: null,
      groups: [],
    });
  };

  const register = async (email: string, password: string): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email },
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
  };

  const completeNewPassword = async (
    user: CognitoUser | any,
    newPassword: string
  ): Promise<void> => {
    await Auth.completeNewPassword(user, newPassword, {});
    await login(user.challengeParam.userAttributes.email, newPassword);
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  };

  const changePassword = async (user: CognitoUser | any, passwords: any) => {
    const { currentPassword, newPassword } = passwords;
    loading(AuthLoading.changePassword);
    return await Auth.changePassword(user, currentPassword, newPassword).finally(() =>
      loadingFinished(AuthLoading.changePassword)
    );
  };

  const setUserGdpr = async (userId: string, gdprApprovedAt: Date) => {
    return API.post("API", `/users/${userId}/gdpr`, { body: { gdprApprovedAt } })
      .then((user) => {
        console.log("response user set gdpr", user);

        setState({
          ...state,
          user: { ...user },
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const forgotPassword = async (email: string) => {
    await Auth.forgotPassword(email);
  };

  const forgotPasswordSubmit = async (email: string, code: string, newPassword: string) => {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        completeNewPassword,
        changePassword,
        forgotPassword,
        forgotPasswordSubmit,
        setUserGdpr,
        refreshUserMe,
      }}
    >
      {state.isInitialized ? <>{children}</> : <LayoutSplashScreen />}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
