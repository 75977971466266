/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React from "react";
import { useLayout, usePageData } from "../../../../_metronic/layout/core";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { LanguageSelectorDropdown } from "../../../../_metronic/partials/layout/header-menus/LanguageSelectorDropdown";

export function SignHeader() {
  const { config, classes, attributes } = useLayout();
  const { header } = config;
  const { pageTitle, pageDescription } = usePageData();

  return (
    <div
      id='kt_header'
      className={clsx("header", classes.header.join(" "), "align-items-stretch")}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(" "),
          "d-flex align-items-stretch justify-content-between"
        )}
      >
        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {header.left === "page-title" && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <Link to='/' className={"me-6"}>
                <img alt='Logo' src={toAbsoluteUrl("/media/logos/logo.png")} className='h-50px' />
              </Link>
              {pageTitle && (
                <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                  {pageTitle}
                  {pageDescription && config.pageTitle && config.pageTitle.description && (
                    <>
                      <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                      <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
                    </>
                  )}
                </h1>
              )}
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <div style={{ marginRight: 24, marginTop: 12 }}>
              <LanguageSelectorDropdown />
            </div>
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  );
}
