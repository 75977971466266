/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";
import { UserActionDialog } from "../UserActionDialog/UserActionDialog";
import { Field, Form, Formik } from "formik";
import { UserLoading } from "../../../../../contexts/users-context";
import { useLoadings } from "../../../../../hooks/use-loadings";
import * as Yup from "yup";

interface Params {
  type: string;
  setActionDialog: Function;
  actionDialog: boolean;
  executeAction: Function;
}

const ActionsCard: React.FC<Params> = ({ type, setActionDialog, actionDialog, executeAction }) => {
  const { loadings } = useLoadings();
  return (
    <>
      <UserActionDialog
        openDialog={actionDialog}
        type={type}
        setOpenDialog={setActionDialog}
        action={executeAction}
      />
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_deactivate'
          aria-expanded='true'
          aria-controls='kt_account_deactivate'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              <FormattedMessage id={`USERS.${type}.TITLE`} />
            </h3>
          </div>
        </div>

        <div id='kt_account_deactivate' className='collapse show'>
          <Formik
            initialValues={{ confirm: false }}
            validationSchema={Yup.object().shape({
              confirm: Yup.boolean().required().oneOf([true]),
            })}
            onSubmit={(values, { resetForm }) => {
              setActionDialog(true);
              resetForm();
            }}
          >
            {({ values }) => (
              <Form noValidate>
                <div className='card-body border-top p-9'>
                  <div>
                    <div className='form-check form-check form-check-custom form-check-solid'>
                      <Field
                        type={"checkbox"}
                        id={`confirm-${type}`}
                        name={`confirm`}
                        className='form-check-input'
                      />
                      <label
                        className='form-check-label fw-bold mb-0 ms-2 fs-6'
                        htmlFor={`confirm-${type}`}
                      >
                        <FormattedMessage id={`USERS.${type}.DESCRIPTION`} />
                      </label>
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button
                    id='kt_account_deactivate_account_submit'
                    type='submit'
                    className='btn btn-info fw-bold'
                    disabled={!values.confirm}
                  >
                    {!loadings.includes(
                      UserLoading[executeAction.name as keyof typeof UserLoading]
                    ) ? (
                      <FormattedMessage id={`USERS.${type}.TITLE`} />
                    ) : (
                      <span className='indicator-progress' style={{ display: "block" }}>
                        <FormattedMessage id={"GLOBAL.PLEASE_WAIT"} />
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </span>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export { ActionsCard };
