export enum DocumentType {
  "Custom" = "custom",
  "Template" = "template",
}

export enum SignatureType {
  "SIMPLE" = "SIMPLE",
  "ADVANCED" = "ADVANCED",
}

export enum DocumentName {
  "BeneficialOwnerDeclaration" = "BeneficialOwnerDeclaration",
  "LegalEntity" = "LegalEntity",
  "LegalEntityAgent" = "LegalEntityAgent",
  "NaturalPerson" = "NaturalPerson",
  "NaturalPersonAgent" = "NaturalPersonAgent",
  "RealPriceCertificate" = "RealPriceCertificate",
  "FlatSalesAgreement" = "FlatSalesAgreement",
  "FlatSalesMandate" = "FlatSalesMandate",
  "HouseSalesAgreement" = "HouseSalesAgreement",
  "HouseSalesMandate" = "HouseSalesMandate",
}
interface Template {
  id: string;
  name: string;
  disabled?: boolean;
}
export const templates: Template[] = [
  { id: DocumentName.RealPriceCertificate, name: "[FR] Attestation de prix reel" },
  {
    id: DocumentName.FlatSalesAgreement,
    name: "[FR] Compromis de vente (Appartement)",
  },
  {
    id: DocumentName.HouseSalesAgreement,
    name: "[FR] Compromis de vente (Maison)",
  },
  {
    id: DocumentName.BeneficialOwnerDeclaration,
    name: "[FR] Déclaration de bénéficiaire effectif",
  },
  { id: DocumentName.FlatSalesMandate, name: "[FR] Mandat de vente (Appartement)" },
  { id: DocumentName.HouseSalesMandate, name: "[FR] Mandat de vente (Maison)" },
  { id: DocumentName.LegalEntity, name: "[FR] Personne Morale" },
  { id: DocumentName.LegalEntityAgent, name: "[FR] Personne Morale Mandataire" },
  { id: DocumentName.NaturalPerson, name: "[FR] Personne Physique" },
  {
    id: DocumentName.NaturalPersonAgent,
    name: "[FR] Personne Physique Mandataire",
  },
];
