import { PDFDocument, PDFPage, PDFImage } from "pdf-lib";
import Jimp from "jimp";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { encode } from "base64-arraybuffer";
import axios from "axios";

export const getPdfPreview = async (pdf: any, seal: any, sealPageIndex = 0) => {
  const pdfDoc = await PDFDocument.load(pdf, {
    ignoreEncryption: true,
  });
  const page = pdfDoc.getPage(sealPageIndex);
  const pngImage = await pdfDoc.embedPng(seal);
  const pngDims = pngImage.scale(0.5);

  page.drawImage(pngImage, {
    x: page.getWidth() - pngDims.width,
    y: 0,
    width: pngDims.width,
    height: pngDims.height,
  });

  return await pdfDoc.saveAsBase64();
};

export async function downloadPdfFileAsBase64(url: string) {
  try {
    const response = await axios({
      url: url,
      method: "GET",
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "*",
      },
    });

    const fileBase64 = encode(response.data);
    return "data:application/pdf;base64," + fileBase64;
  } catch (err) {
    console.error("Failed to download file", err);
  }
}

export const blobToBase64 = (file: File): any =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export const getLogoCropped = async (file: any) => {
  const fileBase64: any = await blobToBase64(file);

  const logo = await Jimp.read(fileBase64);

  const logoWidth = logo.bitmap.width;
  const logoHeight = logo.bitmap.height;

  const aspectRatio = logoWidth / logoHeight;

  // let paddingLeft = 260;
  let paddingTop = 10; //default 10 pixel;

  // const sealTemplate = await Jimp.read(toAbsoluteUrl("/media/templates/seal_template.png"));

  let widthT = 0;
  let heightT = 0;
  if (logoWidth > logoHeight) {
    widthT = 210;
    heightT = widthT / aspectRatio;

    // paddingTop = (sealTemplate.bitmap.height - heightT) / 2;
  } else {
    heightT = 200 - paddingTop;
    widthT = 200 * aspectRatio;
    // paddingLeft = 260 + (sealTemplate.bitmap.width - 260 - widthT) / 2;
  }

  await logo.resize(widthT, heightT);

  return await logo.getBufferAsync("image/png");
};

//get LuxTrust Seal from the image 
export const getSeal = async (logoUrl: any) => {
  
  //set the default width and height for seal
  const sealWidth = 380;
  const sealHeight = 114;

  const sealTemplate = await Jimp.read(
    toAbsoluteUrl("/media/templates/seal_template.png")
  );

  await sealTemplate.resize(sealWidth, sealHeight);

  return await sealTemplate.getBase64Async("image/png");
};

export const getSealPositionCoordinates = (
  page: PDFPage,
  sealImage: PDFImage,
  scale: number = 1,
  x: number,
  y: number
) => {
  const sealDimensions = sealImage.scale(scale);

  return {
    x: page.getWidth() - sealDimensions.width,
    y: 0,
    width: sealDimensions.width,
    height: sealDimensions.height,
  };
};

export function _base64ToArrayBuffer(base64: any) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
