export interface ISignatory {
  signatoryId?: string;
  signatoryFullName?: string;
  signatoryEmail?: string;
  signatoryMobile?: string;
  signatorySigningDate?: Date;
  signatorySigningLocation?: string;
  signatoryLastOtpRequest?: Date;
  signatorySigningStatus: string;
  signatoryOrder: number;
  isShapePlaced: boolean;
}

export enum ESignatoryStatus {
  INIT = "INIT",
  IN_PROGRESS = "IN_PROGRESS",
  SIGNED = "SIGNED",
}

export enum ESignatureStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SIGNED = "SIGNED",
}
