import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { useAuth } from "../hooks/use-auth";
import { MenuTestPage } from "../pages/MenuTestPage";
import { DefaultPrivateLayout } from "../../_metronic/layout/DefaultPrivateLayout";
import { Error404 } from "../modules/errors/components/Error404";
import { isAdmin } from "../utils/authUtils";
import { UserEdit } from "../modules/admin/users/components/UserEdit";
import { PageTitle } from "../../_metronic/layout/core";
import { useIntl } from "react-intl";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { ChangePassword } from "../modules/admin/users/components/ChangePassword";

const PrivateRoutes = () => {
  const { user } = useAuth();
  const BuilderPageWrapper = lazy(() => import("../pages/layout-builder/BuilderPageWrapper"));
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const UsersPage = lazy(() => import("../modules/admin/users/UsersPage"));
  const SignaturesPage = lazy(() => import("../modules/signatures/SignaturesPage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const intl = useIntl();

  return (
    <Routes>
      {isAdmin(user) ? (
        <Route element={<DefaultPrivateLayout />}>
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          <Route
            path='users/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        </Route>
      ) : (
        <Route element={<DefaultPrivateLayout />}>
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          <Route
            path='signatures/*'
            element={
              <SuspensedView>
                <SignaturesPage />
              </SuspensedView>
            }
          />
        </Route>
      )}

      <Route element={<DefaultPrivateLayout />}>
        <Route
          path='profile/:userId'
          element={
            <>
              <SuspensedView>
                <PageTitle breadcrumbs={[]}>
                  {intl.formatMessage({ id: "GLOBAL.ACCOUNT_SETTING" })}
                </PageTitle>
                <UserEdit />
                <ChangePassword />
              </SuspensedView>
            </>
          }
        />
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='init/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Error404 />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
