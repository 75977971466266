import React from "react";
import { CustomMoreActionsPopover } from "./CustomMoreActionsPopover";

export const renderToolbar = (Toolbar: any) => (
  <Toolbar>
    {(slots: any) => {
      const {
        CurrentPageInput,
        Download,
        EnterFullScreen,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        Print,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
      } = slots;
      return (
        <div className={"rpv-toolbar"}>
          <div className={"rpv-toolbar__left"}>
            <div className={"rpv-toolbar__item"}>
              <ShowSearchPopover />
            </div>
            <div className={"rpv-core__display--hidden rpv-core__display--block-small"}>
              <div className={"rpv-toolbar__item"}>
                <GoToPreviousPage />
              </div>
            </div>
            <div className={"rpv-toolbar__item"}>
              <CurrentPageInput />
              / <NumberOfPages />
            </div>
            <div className={"rpv-core__display--hidden rpv-core__display--block-small"}>
              <div className={"rpv-toolbar__item"}>
                <GoToNextPage />
              </div>
            </div>
          </div>
          <div className={"rpv-toolbar__center"}>
            <div className={"rpv-toolbar__item"}>
              <ZoomOut />
            </div>
            <div className={"rpv-core__display--hidden rpv-core__display--block-small"}>
              <div className={"rpv-toolbar__item"}>
                <Zoom />
              </div>
            </div>
            <div className={"rpv-toolbar__item"}>
              <ZoomIn />
            </div>
          </div>
          <div className={"rpv-toolbar__right"}>
            <div className='rpv-core__display--hidden rpv-core__display--block-medium'>
              <EnterFullScreen />
            </div>
            <div className='rpv-core__display--hidden rpv-core__display--block-medium'>
              <Download />
            </div>
            <div className='rpv-core__display--hidden rpv-core__display--block-medium'>
              <Print />
            </div>
          </div>
          <div className={"rpv-toolbar__item"}>
            <CustomMoreActionsPopover toolbarSlot={slots} />
          </div>
        </div>
      );
    }}
  </Toolbar>
);
