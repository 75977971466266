import type { FC, ReactNode } from "react";
import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

type Snackbar = {
  id: string;
  content: { type: string; body: string };
};
type Snackbars = Snackbar[];

interface SnackbarContextValue {
  snackbars: Snackbar[];
  createSnackbar: (content: { type: string; body: string }) => void;
  removeSnackbar: (snackbarId: string) => void;
}
interface SnackbarsProviderProps {
  children?: ReactNode;
}
export const SnackbarsContext = createContext<SnackbarContextValue>({
  snackbars: [],
  createSnackbar: (content: { type: string; body: string }) => undefined,
  removeSnackbar: (snackbarId: string) => undefined,
});

export const SnackbarsProvider: FC<SnackbarsProviderProps> = (props) => {
  const { children } = props;
  const [snackbars, setSnackbars] = useState<Snackbars>([]);

  const createSnackbar = (content: { type: string; body: string }) => {
    const newSnackbar: Snackbar = {
      id: uuid(),
      content: { type: content.type, body: content.body },
    };
    setSnackbars((existingSnackbar) => [...existingSnackbar, newSnackbar]);
  };

  const removeSnackbar = (snackbarId: string) => {
    setSnackbars(snackbars.filter((snackbar) => snackbar.id !== snackbarId));
  };

  return (
    <SnackbarsContext.Provider
      value={{
        snackbars,
        createSnackbar,
        removeSnackbar,
      }}
    >
      {children}
    </SnackbarsContext.Provider>
  );
};

SnackbarsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SnackbarsConsumer = SnackbarsContext.Consumer;
