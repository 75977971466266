import React, { useEffect, useState } from "react";

interface Props {
  user: any;
}
export const Thumbnail: React.FC<Props> = ({ user }) => {
  const [thumbnailValid, setThumbnailValid] = useState<boolean>(false);

  useEffect(() => {
    const isValid = async (url: string) => {
      const thumbnailUrl = url.replace("avatar.png", "thumbnail.png") + "?" + new Date().getTime();
      fetch(thumbnailUrl).then((res) => {
        setThumbnailValid(res.status === 200);
      });
    };

    if (user.logoUrl) {
      isValid(user.logoUrl).then();
    }
  }, [user.logoUrl]);

  return (
    <>
      {thumbnailValid ? (
        <div className={"symbol symbol-35 bg-white-o-30 flex-shrink-0 mx-3"}>
          <img
            style={{ objectFit: "contain" }}
            src={user.logoUrl.replace("avatar.png", "thumbnail.png") + "?" + new Date().getTime()}
            alt=''
          />
        </div>
      ) : (
        <div className={"symbol symbol-30 symbol-light-primary flex-shrink-0  mx-3"}>
          <span className='symbol-label font-size-h5 font-weight-bold symbol-label'>
            <>
              {!!user?.name
                ? user.name[0].toUpperCase()
                : !!user?.email
                ? user?.email[0].toUpperCase()
                : "-"}
            </>
          </span>
        </div>
      )}
    </>
  );
};
