import React, { FC, useEffect } from "react";
import { PageDataProvider, PageTitle, useLayout } from "../../../../_metronic/layout/core";
import { Content } from "../../../../_metronic/layout/components/Content";
import { Footer } from "../../../../_metronic/layout/components/Footer";
import { cloneDeep } from "lodash";
import { SignHeader } from "./SignHeader";

export const SignLayout: FC<any> = ({ children, signatureTitle, footer }: any) => {
  const { config, setLayout } = useLayout();

  useEffect(() => {
    const newConfig = cloneDeep(config);
    newConfig.aside.display = false;
    newConfig.toolbar.display = false;
    newConfig.header.left = "page-title";
    newConfig.pageTitle = {
      display: true,
      breadCrumbs: false,
      layout: "default",
      responsive: false,
      description: false,
      direction: "row",
      responsiveBreakpoint: "300px",
      responsiveTarget: "",
    };
    setLayout(newConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageDataProvider>
      <PageTitle breadcrumbs={[]}>{signatureTitle ?? ""}</PageTitle>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <SignHeader />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <div className='card h-100'>
                  <div
                    className={
                      "card-body position-relative p-0 d-flex align-items-center justify-content-center"
                    }
                  >
                    {children}
                  </div>
                  {!!footer && <div className={"card-footer py-6"}>{footer}</div>}
                </div>
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </PageDataProvider>
  );
};
