import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import React, { FC, useEffect, useState } from "react";
import { renderToolbar } from "./CustomToolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "./styles.scss";
import { downloadPdfFileAsBase64 } from "../../../modules/signatures/components/imageUtils";

//TODO add language support
export const PdfViewer: FC<{
  fileUrl: string;
  sealPreview?: any;
  sealPageIndex?: number;
  defaultScale?: number;
}> = ({ fileUrl, sealPageIndex, defaultScale = 0 }: any) => {
  const [file, setFile] = useState<any>(fileUrl);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });
  useEffect(() => {
    if (fileUrl) {
      const typeOf = typeof fileUrl;

      switch (fileUrl) {
        case typeOf === "string" && fileUrl.startsWith("http"):
          downloadPdfFileAsBase64(fileUrl).then((fileBase64) => setFile(fileBase64));
          break;
        case typeOf === "string" && fileUrl.startsWith("data:application/pdf;base64,"):
          setFile(fileUrl.replace("data:application/pdf;base64,", ""));
          break;
        default:
          setFile(fileUrl);
      }
    }
  }, [fileUrl, sealPageIndex]);

  return (
    <Worker workerUrl='/pdf.worker.min.js'>
      <Viewer
        fileUrl={file}
        // initialPage={sealPageIndex}
        defaultScale={defaultScale}
        plugins={[defaultLayoutPluginInstance]}
        renderLoader={(percentages) => <div />}
      />
    </Worker>
  );
};
