/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link } from "react-router-dom";
import { Languages } from "./Languages";
import { useAuth } from "../../../../app/hooks/use-auth";
import { FormattedMessage } from "react-intl";

const HeaderUserMenu: FC = () => {
  const { user, logout } = useAuth();
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 min-w-275px w-auto'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='d-flex flex-column'>
          <div className='fw-bolder d-flex align-items-center fs-5'>
            {user?.cognitoUser?.attributes.email}
          </div>
        </div>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/profile/me' className='menu-link px-5'>
          <FormattedMessage id={"GLOBAL.ACCOUNT_SETTING"} />
        </Link>
      </div>

      <Languages />

      <div className='menu-item menu-item-state-bg-light-danger px-5'>
        <a onClick={logout} className='menu-link px-5'>
          <FormattedMessage id={"AUTH.SIGN_OUT"} />
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
