import React from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { FormattedMessage } from "react-intl";

import "./SimpleCard.scss";

interface Params {
  chartColor: string;
  icon?: string;
  info: {
    title: string;
    data: string;
  };
}
export const SimpleCard: React.FC<Params> = ({ chartColor, icon, info }) => {
  return (
    <div className={`col bg-white px-6 py-8 rounded-2`}>
      <div className={"d-flex justify-content-between"}>
        <div>
        <div className={`fw-bolder fs-3 text-black`}>{info.data}</div>
          <div className='text-black fw-bold fs-6 mt-2'>
            <FormattedMessage id={info.title} values={{ count: info.data }} />
          </div>
        </div>
        {icon && (
          <div>
            <KTSVG path={icon} className={`svg-icon-3x d-block svg-icon-${chartColor} svg-color-success my-2 mx-4`}/>
          </div>
        )}
      </div>
    </div>
  );
};
