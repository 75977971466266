import React, { useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { FormattedMessage } from "react-intl";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Checkbox } from "../../../components/Checkbox";

export function AcceptGdpr() {
  const auth = useAuth();
  const [agreeGDPR, setAgreeGDPR] = useState(false);
  const [agreeMobile, setAgreeMobile] = useState(false);
  const [loading, setLoading] = useState(false);

  const acceptGDPR = async () => {
    if (agreeGDPR && agreeMobile && auth.user?.cognitoUser.username) {
      setLoading(true);
      await auth?.setUserGdpr(auth?.user?.cognitoUser?.username, new Date());
    }
  };

  return (
    <>
      {auth?.user ? (
        <div className='mx-8' style={{ maxWidth: "550px" }}>
          <div className='text-center mb-10 mb-lg-20'>
            <h3 className='font-size-h1'>
              <FormattedMessage id='AUTH.GDPR.TITLE' />
            </h3>
            <p className='text-muted font-weight-bold'>
              <FormattedMessage id='AUTH.GDPR.SUBTITLE' />
            </p>
          </div>
          <div className='d-flex align-items-center flex-grow-1 mb-4'>
            <Checkbox
              translationId={"AUTH.GDPR.TERMS_PRIVACY_DESCRIPTION"}
              val={agreeGDPR}
              setVal={setAgreeGDPR}
              params={{
                termsLink: (chunks: any) => (
                  <u>
                    <a
                      href={
                        "https://www.chambre-immobiliere.lu/mentions-legales-et-declaration-de-confidentialite/"
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      {chunks}
                    </a>
                  </u>
                ),
                privacyPolicyLink: (chunks: any) => (
                  <u>
                    <a
                      href={
                        "https://www.chambre-immobiliere.lu/mentions-legales-et-declaration-de-confidentialite/"
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      {chunks}
                    </a>
                  </u>
                ),
                companyName: auth?.user?.additionalInfo?.company?.name || auth?.user?.name,
              }}
            />
          </div>
          <div className='d-flex align-items-center flex-grow-1'>
            <Checkbox
              translationId={"AUTH.GDPR.TERMS_MOBILE_DESCRIPTION"}
              val={agreeMobile}
              setVal={setAgreeMobile}
              params={{
                span: (chunks: any) => (
                  <span className='text-nowrap font-weight-bold'>{chunks}</span>
                ),
                mobile: formatPhoneNumberIntl(auth?.user?.mobile),
                companyName: auth?.user?.additionalInfo?.company?.name || auth?.user?.name,
              }}
            />
          </div>
          <div className='d-flex justify-content-end mt-8'>
            <button className='btn btn-outline-danger font-weight-bold' onClick={auth.logout}>
              <FormattedMessage id={"AUTH.GDPR.ACTION_DISAGREE"} />
            </button>
            <button
              disabled={!agreeGDPR || !agreeMobile}
              className='btn btn-primary font-weight-bold px-9 py-4'
              style={{ marginLeft: 8 }}
              data-cy='button-gdpr-agree'
              onClick={acceptGDPR}
            >
              {!loading && (
                <span className='indicator-label'>
                  <FormattedMessage id={"AUTH.GDPR.ACTION_AGREE"} />
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{ display: "block" }}>
                  <FormattedMessage id={"GLOBAL.PLEASE_WAIT"} />
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className={"spinner spinner-lg spinner-primary"}></div>
      )}
    </>
  );
}
