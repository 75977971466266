import type { FC, ReactNode } from "react";
import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { isAdmin } from "../utils/authUtils";
import { useAuth } from "../hooks/use-auth";
import { _base64ToArrayBuffer, getSeal } from "../modules/signatures/components/imageUtils";
import { useSnackbars } from "../hooks/use-snackbars";
import { SNACKBAR_MESSAGE } from "../components/SnackbarsHandler";
import { useLoadings } from "../hooks/use-loadings";

type Users = any[];

export enum UserLoading {
  fetchUsers = "fetchUsers",
  fetchUserById = "fetchUserById",
  createUser = "createUser",
  updateUser = "updateUser",
  deleteUser = "deleteUser",
  setUserAvatar = "setUserAvatar",
  createUserAccess = "createUserAccess",
  deleteUserAccess = "deleteUserAccess",
  fetchDashboard = "fetchDashboard",
}

export interface UsersContextValue {
  users: Users;
  selectedUser?: any;
  invalidEmail: string | undefined;
  openDeleteDialog: boolean;
  openDisabledDialog: boolean;
  openActiveAccountDialog: boolean;
  dashboard: any;
  fetchUsers: () => Promise<void>;
  fetchUserById: (userId: string | undefined) => Promise<void>;
  createUser: (user: any) => Promise<void>;
  updateUser: (user: any) => Promise<void>;
  deleteUser: (userId: string | undefined) => Promise<void>;
  updateUserAppLanguage: (user: any) => Promise<void>;
  setUserAvatar: (params: any) => Promise<any>;
  setOpenDeleteDialog: (condition: boolean) => void;
  setOpenActiveAccountDialog: (condition: boolean) => void;
  setOpenDisabledDialog: (condition: boolean) => void;
  setSelectedUser: (user: any) => void;
  createUserAccess: (user: any) => Promise<void>;
  deleteUserAccess: (user: any) => Promise<void>;
  fetchDashboard: () => Promise<void>;
  generateThumbnail: (userId: string | undefined) => Promise<any>;
}

interface UsersProviderProps {
  children?: ReactNode;
}

const initialUsers: Users = [];

export const UsersContext = createContext<UsersContextValue>({
  users: initialUsers,
  selectedUser: undefined,
  invalidEmail: undefined,
  openDeleteDialog: false,
  openDisabledDialog: false,
  openActiveAccountDialog: false,
  dashboard: undefined,
  fetchUsers: () => Promise.resolve(),
  fetchUserById: (userId) => Promise.resolve(),
  createUser: (user) => Promise.resolve(),
  updateUser: (user) => Promise.resolve(),
  deleteUser: (userId) => Promise.resolve(),
  updateUserAppLanguage: (user) => Promise.resolve(),
  setUserAvatar: (params) => Promise.resolve(),
  setOpenDeleteDialog: (isOpen) => undefined,
  setOpenActiveAccountDialog: (isOpen) => undefined,
  setOpenDisabledDialog: (isOpen) => undefined,
  setSelectedUser: (user: any) => undefined,
  createUserAccess: (userId) => Promise.resolve(),
  deleteUserAccess: (userId) => Promise.resolve(),
  fetchDashboard: () => Promise.resolve(),
  generateThumbnail: (userId: string | undefined) => Promise.resolve(),
});

export const UsersProvider: FC<UsersProviderProps> = (props) => {
  const { loading, loadingFinished } = useLoadings();
  const { children } = props;
  const [users, setUsers] = useState<Users>(initialUsers);
  const [selectedUser, setSelectedUser] = useState<any>(undefined);
  const [invalidEmail, setInvalidEmail] = useState<string | undefined>(undefined);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openActiveAccountDialog, setOpenActiveAccountDialog] = useState<boolean>(false);
  const [openDisabledDialog, setOpenDisabledDialog] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<any>(undefined);

  const navigate = useNavigate();
  const auth = useAuth();
  const { createSnackbar } = useSnackbars();

  const fetchUsers = async () => {
    loading(UserLoading.fetchUsers);
    API.get("API", "/users", {})
      .then(async (response) => {
        response = await Promise.all(
          response.map(async (user: any): Promise<any> => {
            const url =
              user.logoUrl.replace("avatar.png", "thumbnail.png") + "?" + new Date().getTime();
            user.thumbnailValid = await fetch(url).then((res) => {
              return res.status === 200;
            });
            return user;
          })
        );
        setUsers(response || []);
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.FETCH_USERS);
      })
      .finally(() => {
        loadingFinished(UserLoading.fetchUsers);
      });
  };

  const fetchUserById = async (userId: string | undefined) => {
    setSelectedUser(undefined);
    if (
      userId &&
      userId !== "new" &&
      (userId === "me" || (userId !== "me" && isAdmin(auth.user)))
    ) {
      loading(UserLoading.fetchUserById);
      API.get("API", `/users/${userId}`, {})
        .then((response) => {
          setSelectedUser(response);
        })
        .catch((e) => {
          if (e.response.status === 405) {
            auth.logout().then(() => {
              document.location.reload();
            });
          } else {
            createSnackbar(SNACKBAR_MESSAGE.DANGER.FETCH_USER);
          }
        })
        .finally(() => {
          loadingFinished(UserLoading.fetchUserById);
        });
    }
  };

  const createUser = async (user: any) => {
    loading(UserLoading.createUser);
    setInvalidEmail(undefined);
    API.post("API", "/users", { body: user })
      .then((response) => {
        setSelectedUser(response.data);
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.CREATE_USER);
        loadingFinished(UserLoading.createUser);
        navigate(`/users/${response.id}`);
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setInvalidEmail(user.email);
          createSnackbar(SNACKBAR_MESSAGE.DANGER.EMAIL_EXIST);
        } else {
          createSnackbar(SNACKBAR_MESSAGE.DANGER.CREATE_USER);
        }
      })
      .finally(() => {
        loadingFinished(UserLoading.createUser);
      });
  };

  const updateUserAppLanguage = async (user: any) => {
    return API.post("API", `/users/${user.id}/language`, { body: user })
      .then((response) => {
        setSelectedUser(response);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateUser = async (user: any) => {
    loading(UserLoading.updateUser);
    setInvalidEmail(undefined);
    // console.log("updateUser:", user);
    API.post("API", `/users/${user.id}`, { body: user })
      .then((response) => {
        setSelectedUser(response);
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.UPDATE_USER);
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setInvalidEmail(user.email);
        }
        createSnackbar(SNACKBAR_MESSAGE.DANGER.UPDATE_USER);
      })
      .finally(() => {
        loadingFinished(UserLoading.updateUser);
      });
  };

  const deleteUser = async (userId: string | undefined) => {
    loading(UserLoading.deleteUser);
    API.del("API", `/users/${userId}`, {})
      .then((response) => {
        setUsers(users.filter((user) => user.id !== userId));
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.DELETE_USER);
        loadingFinished(UserLoading.deleteUser);
        navigate("/users");
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.DELETE_USER);
      })
      .finally(() => {
        setOpenDeleteDialog(false);
        loadingFinished(UserLoading.deleteUser);
      });
  };

  const setUserAvatar = async (params: any): Promise<any> => {
    loading(UserLoading.setUserAvatar);
    const response = await API.get("API", `/users/${params.user.id}/avatar`, {}).catch(() => {
      createSnackbar(SNACKBAR_MESSAGE.DANGER.UPDATE_USER);
    });
    try {
      await fetch(response.avatar.url, {
        method: "PUT",
        body: params.file,
        headers: {
          "Content-Type": "image/png",
        },
      });

      const v = await getSeal(params.file);

      await fetch(response.sealTemplate.url, {
        method: "PUT",
        body: _base64ToArrayBuffer(v.replace("data:image/png;base64,", "")),
        headers: {
          "Content-Type": "image/png",
        },
      });
      const { url } = await generateThumbnail(params.user.id);
      const urlLogo = response.avatar.url.split("?")[0];
      const urlSealTemplate = response.sealTemplate.url.split("?")[0];
      const urlThumbnail = url.split("?")[0];
      return {
        urlLogo,
        urlSealTemplate,
        urlThumbnail,
      };
    } catch (e) {
      loadingFinished(UserLoading.setUserAvatar);
      createSnackbar(SNACKBAR_MESSAGE.DANGER.UPDATE_USER);
      console.log("axios error:");
      console.log(e);
      return "";
    }
  };

  const createUserAccess = async (userId: string | undefined) => {
    loading(UserLoading.createUserAccess);
    API.post("API", `/users/${userId}/access`, {})
      .then((result) => {
        if (result.user) {
          setSelectedUser(result.user);
        }
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.CREATE_USER_ACCESS);
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.CREATE_USER_ACCESS);
      })
      .finally(() => {
        setOpenActiveAccountDialog(false);
        loadingFinished(UserLoading.createUserAccess);
      });
  };

  const deleteUserAccess = async (userId: string | undefined) => {
    loading(UserLoading.deleteUserAccess);
    API.del("API", `/users/${userId}/access`, {})
      .then((result) => {
        if (result.user) {
          setSelectedUser(result.user);
        }
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.REMOVE_USER_ACCESS);
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.REMOVE_USER_ACCESS);
      })
      .finally(() => {
        setOpenDisabledDialog(false);
        loadingFinished(UserLoading.deleteUserAccess);
      });
  };

  const fetchDashboard = async () => {
    loading(UserLoading.fetchDashboard);
    API.get("API", "/dashboard", {})
      .then((response) => {
        setDashboard(response);
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.DASHBOARD);
      })
      .finally(() => {
        loadingFinished(UserLoading.fetchDashboard);
      });
  };

  const generateThumbnail = async (userId: string | undefined) => {
    return API.post("API", `/users/${userId}/avatar`, {})
      .then((response) => {
        return { ...response };
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.UPDATE_USER);
      });
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        selectedUser,
        setSelectedUser,
        invalidEmail,
        fetchUsers,
        fetchUserById,
        createUser,
        updateUser,
        deleteUser,
        updateUserAppLanguage,
        setUserAvatar,
        openDeleteDialog,
        setOpenDeleteDialog,
        setOpenActiveAccountDialog,
        setOpenDisabledDialog,
        createUserAccess,
        deleteUserAccess,
        openDisabledDialog,
        openActiveAccountDialog,
        fetchDashboard,
        dashboard,
        generateThumbnail,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

UsersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const UsersConsumer = UsersContext.Consumer;
