import { toAbsoluteUrl } from "../helpers";

export const AVAILABLE_LANGUAGES = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/united-kingdom.svg"),
  },
  {
    lang: "fr",
    name: "Français",
    flag: toAbsoluteUrl("/media/flags/france.svg"),
  },
  {
    lang: "de",
    name: "Deutsch",
    flag: toAbsoluteUrl("/media/flags/germany.svg"),
  },
  {
    lang: "lu",
    name: "Lëtzebuergesch",
    flag: toAbsoluteUrl("/media/flags/luxembourg.svg"),
  },
];
