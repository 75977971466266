import { ProgressBar, SimpleCard } from "../../components/widget";
import React, { useEffect } from "react";
import { useUsers } from "../../hooks/use-users";
import { FormattedMessage } from "react-intl";
import { incrementStatusCounter } from "../../utils/utils";
import { useAuth } from "../../hooks/use-auth";

export const AdminDashboard = () => {
  const { user } = useAuth();
  const { fetchUsers, users } = useUsers();
  useEffect(() => {
    fetchUsers().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usersStatistic = (() => {
    let totalCount: any = {
      SIGNED: 0,
      IN_PROGRESS: 0,
      CANCEL: 0,
    };
    for (const user of users) {
      totalCount = incrementStatusCounter(totalCount, user?.counterSignatureRequest);
    }
    const totalSignature = totalCount.SIGNED + totalCount.IN_PROGRESS + totalCount.CANCEL;
    return {
      totalCount,
      completionRate:
        totalSignature === 0 ? 0 : Math.round((totalCount.SIGNED / totalSignature) * 100),
    };
  })();
  return (
    <div className={"row gy-5 g-xl-8"}>
      <div className={"col-xxl-6"}>
        <div className={"card card-custom bg-white mb-4 mb-xl-8"}>
          <div className={"card-body d-flex align-items-center justify-content-between flex-wrap"}>
            <div className={"mr-2"}>
              <h3 className={"font-weight-bolder"}>
                <FormattedMessage
                  id={"GLOBAL.HELLO"}
                  values={{ name: user?.name ? `, ${user?.name}` : "" }}
                />
              </h3>
              <i>
                <div>{new Date().toLocaleDateString()}</div>
              </i>
            </div>
          </div>
        </div>
        <div className={"row gy-5 g-xl-8"}>
          <div className={"col-md-8"}>
            <ProgressBar
              className={"mb-4 mb-xl-8"}
              title={"GLOBAL.COMPLETION_RATE"}
              description={""}
              color={
                usersStatistic.completionRate > 66
                  ? "success"
                  : usersStatistic.completionRate > 33
                  ? "warning"
                  : "danger"
              }
              progress={`${usersStatistic.completionRate}%`}
            />
            <div className={"card card-custom"}>
              <SimpleCard
                chartColor={"info"}
                icon={"/media/icons/duotune/files/fil008.svg"}
                info={{
                  data: `${usersStatistic.totalCount.SIGNED}`,
                  title: "GLOBAL.SIGNATURE_REALISED",
                }}
              />
            </div>
          </div>
          <div className={"d-none d-md-flex col-md-4"}>
            <div className={"card bg-transparent overflow-hidden"}>
              <img
                alt={"dashboard"}
                src={"/media/dashboard/cigdl.jpg"}
                className={"h-100"}
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"col-xxl-6"}>
        <div className={"card card-custom mb-4 mb-xl-8"}>
          <SimpleCard
            chartColor={"success"}
            icon={"/media/icons/duotune/abstract/abs014.svg"}
            info={{
              data: (
                users.filter((user: { isDisabled: boolean }) => !user.isDisabled).length ?? 0
              ).toString(),
              title: "GLOBAL.ACTIVE_MEMBER",
            }}
          />
        </div>
        <div className={"card card-custom"}>
          <SimpleCard
            chartColor={"primary"}
            icon={"/media/icons/duotune/abstract/abs013.svg"}
            info={{
              data: (
                users.filter((user: { isDisabled: boolean }) => user.isDisabled).length ?? 0
              ).toString(),
              title: "GLOBAL.DEACTIVATED_MEMBER",
            }}
          />
        </div>
      </div>
    </div>
  );
};
