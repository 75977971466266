import React from "react";
import { get } from "lodash-es";
import PhoneInput from "react-phone-input-2";
import "./PhoneFormInput.css";

const getFieldCSSClasses = (className, touched, errors, value) => {
  const classes = className.split(" ") ?? [];
  if (touched && errors) {
    classes.push("is-invalid");
  }
  return classes.join(" ");
};

export function PhoneFormInput({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  inline = false,
  classes,
  ...props
}) {
  return (
    <PhoneInput
      country={"lu"}
      {...props}
      {...field}
      placeholder={"+352 698 555 382"}
      inputProps={{
        name: field.name,
        className: getFieldCSSClasses(
          classes,
          get(touched, field.name),
          get(errors, field.name),
          get(values, field.name)
        ),
      }}
      onChange={(phoneNumber) => {
        phoneNumber = "+" + phoneNumber;
        if (!!props.onChange) {
          props.onChange({ target: { name: field.name, value: phoneNumber } });
        }
        setFieldValue(field.name, phoneNumber);
      }}
      specialLabel={""}
    />
  );
}
