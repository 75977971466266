import React, { FC, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { useAuth } from "../../../hooks/use-auth";
import { FormattedMessage } from "react-intl";
import { isAdmin, isMasterUser } from "../../../utils/authUtils";

const initialValues = {
  username: "",
  password: "",
};
const loginSchema = Yup.object().shape({
  username: Yup.string().email().required().label("GLOBAL.EMAIL"),
  password: Yup.string().required().label("GLOBAL.PASSWORD"),
});

export const Login: FC = () => {
  const auth = useAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const submitLogin = async (values: any, { setStatus, setSubmitting }: FormikValues) => {
    setLoading(true);
    setStatus();

    await auth
      .login(values.username, values.password)
      .then((user) => {
        if (isAdmin(user) || isMasterUser(user)) {
          // admin account is cigdl's account only, which is preset, no gdpr required here (skip)
          navigate("/dashboard");
        } else if (!user?.gdprApprovedAt) {
          navigate("/init/gdpr");
        } else if (user?.cognitoUser?.challengeName) {
          navigate(`/challenges/${user?.cognitoUser?.challengeName?.toLowerCase()}`);
        } else {
          console.warn("no redirect");
        }
      })
      .catch((err) => {
        console.error("Login - Error:", err);
        setSubmitting(false);
        setStatus(err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={submitLogin}>
      {({ status, isSubmitting, isValid }) => (
        <Form className='form w-100' noValidate id='kt_login_signin_form'>
          {status && (
            <div className='mb-10 alert alert-danger'>
              <FormattedMessage id={"AUTH.INVALID_LOGIN"} />
            </div>
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              <FormattedMessage id={"GLOBAL.EMAIL"} />
            </label>
            <Field
              name={"username"}
              className={clsx("form-control form-control-lg form-control-solid")}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={"username"} />
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                  <FormattedMessage id={"GLOBAL.PASSWORD"} />
                </label>
                {/* end::Label */}
                {/* begin::Link */}
                <Link
                  to='/auth/forgot_password'
                  className='link-primary fs-6 fw-bolder'
                  style={{ marginLeft: "5px" }}
                >
                  <FormattedMessage id={"AUTH.FORGOT_PASSWORD"} />
                </Link>
                {/* end::Link */}
              </div>
            </div>
            <Field
              name={"password"}
              type='password'
              autoComplete='off'
              className={clsx("form-control form-control-lg form-control-solid")}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={"password"} />
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={isSubmitting || !isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  <FormattedMessage id={"AUTH.SIGN_IN"} />
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{ display: "block" }}>
                  <FormattedMessage id={"GLOBAL.PLEASE_WAIT"} />
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </Form>
      )}
    </Formik>
  );
};
